import { useEffect, useState } from 'react';

import { useBrand } from 'reaxl-brand';
import { useFeatures } from 'reaxl-features';

import { viewCountFetcher } from '@/fetchers';

const baseIconUrl = 'https://www.autotrader.com/content/static/img/icon';

const urgencyTypeValues = {
    incentive: {
        heading: 'Limited Time Offer!',
        imgSrc: `${baseIconUrl}/promocard/affordable_new_cars.svg`,
        imgHeight: '52',
        imgWidth: '52',
    },
    reduced: {
        heading: 'Reduced Price!',
        imgSrc: `${baseIconUrl}/promocard/price_drops.svg`,
        imgHeight: '52',
        imgWidth: '50',
    },
    newlylisted: {
        heading: 'Newly Listed!',
        imgSrc: `${baseIconUrl}/promocard/detailed.svg`,
        imgHeight: '52',
        imgWidth: '50',
    },
    numviewed: {
        heading: 'Act Fast!',
        imgSrc: `${baseIconUrl}/promocard/speed_up_the_deal.svg`,
        imgHeight: '52',
        imgWidth: '50',
    },
};

const usePremiumSpotlightUrgencyDrivers = ({ spotlight }) => {

    const { brand } = useBrand();

    const [urgencyDriver, setUrgencyDriver] = useState({});

    const {
        premium_spotlight: [enablePremiumSpotlight, { top_placement: enableTopPlacement, peekaboo_placement: enablePeekaboo }],
    } = useFeatures(['premium_spotlight']);

    useEffect(() => {
        const fetchViewCount = async ({ vin, dateRange = 7 }) => {
            const result = await viewCountFetcher({ vin, dateRange });
            return result;
        };

        if (spotlight && enablePremiumSpotlight) {
            if (spotlight.topSpecialOffer?.shortName) {
                setUrgencyDriver({ type: 'incentive', subText: spotlight.topSpecialOffer.shortName.replace('for qualified buyers', '') });
            } else if (spotlight.isReducedPrice) {
                setUrgencyDriver({ type: 'reduced' });
            } else if (spotlight.isNewlyListed) {
                setUrgencyDriver({ type: 'newlylisted' });
            } else if (spotlight.vin && (enableTopPlacement || enablePeekaboo)) {
                fetchViewCount({ vin: spotlight.vin }).then((count) => {
                    // NOTE: Only using AT at test launch, but the code is configured to use any brand
                    const viewCount = count?.[brand.toUpperCase()] || 0;
                    if (viewCount > 3) {
                        setUrgencyDriver({ type: 'numviewed', subText: `${viewCount} are Viewing` });
                    }
                });
            }
        }
    }, [brand, enablePeekaboo, enablePremiumSpotlight, enableTopPlacement, spotlight]);

    return { ...urgencyDriver, ...urgencyTypeValues[urgencyDriver.type] };
};

export default usePremiumSpotlightUrgencyDrivers;
