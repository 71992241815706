import React, { useCallback, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { LinkIcon } from 'reaxl';
import { reportListingClick, sendClick } from 'reaxl-analytics';

import {
    srpActiveEmailListingDuck,
    srpActiveInteractionDuck,
} from '@/ducks/srp';

// The contents of the InventoryListing popover
function InventoryQuickActionsContainer({
    listing,
    parentId,
}) {
    const dispatch = useDispatch();

    useEffect(() => {
        if (listing?.id) {
            dispatch(srpActiveEmailListingDuck.creators.setActiveResults([listing.id]));
            dispatch(srpActiveInteractionDuck.creators.setKeys({ parentId }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleReportClick = useCallback(async (event) => {
        sendClick(reportListingClick, event, { inventoryId: listing?.id, par: parentId });
        event.stopPropagation();
        await dispatch(srpActiveInteractionDuck.creators.setKeys({ showReportModal: true }));
    }, [dispatch, listing, parentId]);

    const handleShareClick = useCallback(async (event) => {
        event.stopPropagation();
        sendClick('shareClick', event, { type: 'icon', wasSticky: false, id: listing?.id, includePageId: false, par: parentId });
        await dispatch(srpActiveInteractionDuck.creators.setKeys({ showShareListingModal: true }));
        await dispatch(srpActiveEmailListingDuck.creators.setActiveResults([listing?.id]));
    }, [dispatch, listing?.id, parentId]);

    const ctaClasses = 'display-flex no-wrap margin-bottom-2';
    const glyphClasses = 'text-size-400 margin-right-3 text-accent';

    return (
        <>
            <div className={ctaClasses}>
                <LinkIcon
                    glyph="share"
                    glyphClassName={glyphClasses}
                    placement="before"
                    onClick={handleShareClick}
                    undecorated
                >
                    Share this Listing
                </LinkIcon>
            </div>
            <div className={ctaClasses}>
                <LinkIcon
                    glyph="commenting-o"
                    glyphClassName={glyphClasses}
                    placement="before"
                    onClick={handleReportClick}
                    undecorated
                >
                    Report this Listing
                </LinkIcon>
            </div>
        </>
    );
}

export default InventoryQuickActionsContainer;
