import React from 'react';

import { useSelector } from 'react-redux';

import { SubHeading, Text } from 'reaxl';
import { ContentPreview } from 'reaxl-molecules';

import srpEditorialReviewsDuck from '@/ducks/srp/srpEditorialReviewsDuck';

function EditorialContentContainer() {
    const expertReviewData = useSelector(srpEditorialReviewsDuck.selectors.getDuckState);
    const isValidReviewData = Array.isArray(expertReviewData) && expertReviewData.length !== 0;

    return isValidReviewData && (
        <div className="display-flex flex-column gap-3 border-gray-light border-horizontal-0 margin-vertical-2 padding-vertical-2">
            <SubHeading
                componentClass="h2"
                size={500}
            >
                Expert Reviews
            </SubHeading>
            <div className="row">
                {expertReviewData.slice(0, 2).map((review) => (
                    <ContentPreview
                        key={review.id}
                        category={(
                            <Text
                                weight="bold"
                                color="subdued-lightest"
                            >
                                Car Reviews
                            </Text>
                        )}
                        headline={(
                            <a
                                className="text-bold link-unstyled"
                                href={review?.link}
                                target="_blank"
                            >
                                {review?.title?.rendered}
                            </a>
                        )}
                        // TODO: This data no longer exists. A-Team should decide what to do
                        // summary={
                        //     // eslint-disable-next-line react/no-danger
                        //     <span dangerouslySetInnerHTML={{ __html: _get(review, "acf['blurb-2-line'].text", '') }} />
                        // }
                        orientation="landscape"
                        className="col-md-6 col-xs-12" // using as rootClassName
                    />
                ))}
            </div>
        </div>
    );
}

export default EditorialContentContainer;
