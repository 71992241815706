import React, {
    useCallback,
    useEffect,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';

import qs from 'querystring';

import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import {
    emailSubscribeSignIn,
    facebookSubscribeSignIn,
    getAndSaveConsumerMetadata,
    sendCaptureSubscribeClick,
    sendClick,
    sendSubscribeClick,
} from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';
import { EmailSubscribe } from 'reaxl-organisms';

import LocalStorage from '@/utilities/LocalStorage';

import remoteActions from '@/actions/srp/remoteActions';

import { authDuck, recentSearchDuck, userDuck } from '@/ducks';

import { srpActiveInteractionDuck, srpFiltersDuck, srpSortDuck } from '@/ducks/srp';

import { emailRegistrationServiceTransformer } from '@/transformers';

export function EmailSubscribeContainer() {
    const dispatch = useDispatch();
    const {
        authentication: [, { sign_in_path: signInPath }],
        brand: [, { consumer_brand: consumerBrand }],
        global_personalization: [isGlobalPersonalizationEnabled],
        personalization: [isPersonalizationEnabled] = [],
        SAVE_SEARCH_OFF: [saveSearchDisabled],
    } = useFeatures([
        'authentication',
        'brand',
        'global_personalization',
        'personalization',
        'SAVE_SEARCH_OFF',
    ]);

    const isEmailCaptureModal = useSelector(srpActiveInteractionDuck.selectors.getShowEmailCaptureModal);

    const search = useSelector(userDuck.selectors.getSavedSearch);
    const { saveSearchString } = search;
    const isLatestSearchSubscribed = useSelector((state) => _get(recentSearchDuck.selectors.getRecentSearches(state), '[0].isSubscribed'));
    const showEmailSubscribeModal = useSelector(userDuck.selectors.getShowEmailSubscribeModal);
    const showEmailSubscribeInline = useSelector(userDuck.selectors.getShowEmailSubscribeInline);
    const isLoggedIn = useSelector(authDuck.selectors.isLoggedIn);
    const sort = useSelector(srpSortDuck.selectors.getDuckState, _isEqual);
    const requestValuesWithoutSort = useSelector(srpFiltersDuck.selectors.getRequestValues, _isEqual);

    const isSearchSavable = !isLoggedIn && !(search.title && search.title.length);
    const hasSearchParams = !!(requestValuesWithoutSort && Object.keys(requestValuesWithoutSort).length);
    const isInlineSaveSearchOff = saveSearchDisabled;
    const isInlineSaveSearchEnabled = !isInlineSaveSearchOff && !isLatestSearchSubscribed;
    const showEmailSubscribe = !isInlineSaveSearchOff && hasSearchParams && isSearchSavable && isInlineSaveSearchEnabled && showEmailSubscribeInline;

    const setEmailAlerts = useCallback(() => {
        const parsedQuery = qs.parse(saveSearchString.slice(1));
        const transformedQuery = emailRegistrationServiceTransformer.transformSearch(parsedQuery);
        const requestValues = {
            ...requestValuesWithoutSort,
            sortBy: sort,
        };
        dispatch(remoteActions.setEmailAlertsSearch({
            search: {
                ...transformedQuery,
                requestValues,
            },
            onLogInSuccess: (response) => {
                const {
                    provider,
                } = response;
                if (provider === 'facebook') {
                    sendClick(facebookSubscribeSignIn);
                } else {
                    sendClick(emailSubscribeSignIn);
                }
            },
        }));
    }, [dispatch, saveSearchString, requestValuesWithoutSort, sort]);

    const handleEmailSubscribeClose = () => {
        const Storage = LocalStorage.getInstance();
        // eslint-disable-next-line react-hooks/rules-of-hooks
        Storage.useSession(true);
        Storage.set('emailSubscribe', 'closed');
        dispatch(userDuck.creators.showEmailSubscribeInline(false));
        dispatch(srpActiveInteractionDuck.creators.setKeys({ showEmailCaptureModal: false }));
    };

    const handleSubmit = async (event) => {
        await getAndSaveConsumerMetadata({
            email: event.emailAddress,
            brand: consumerBrand,
            shouldUpdateConsumerMetadata: true,
        });
        if (isEmailCaptureModal) {
            sendClick(sendSubscribeClick, event, {});
            dispatch(srpActiveInteractionDuck.creators.setKeys({ showEmailCaptureModal: false }));
        } else {
            sendClick(sendCaptureSubscribeClick, event, {});
        }
    };

    useEffect(() => {
        if (showEmailSubscribe) {
            setEmailAlerts();
        }
    }, [saveSearchString, setEmailAlerts, showEmailSubscribe]);

    if ((isPersonalizationEnabled || isGlobalPersonalizationEnabled) && showEmailSubscribe) {

        const navigateToSignIn = () => {
            const refUrl = new URL(globalThis.location.href);
            refUrl.pathname = signInPath;
            refUrl.search = new URLSearchParams({
                defaultScreen: 'signup',
                refUrl,
            });
            globalThis.location.assign(refUrl);
        };
        return (
            <EmailSubscribe
                data-cmp="emailSubscribeContainer"
                onSignInClick={navigateToSignIn}
                userDuck={userDuck}
                showEmailSubscribeModal={showEmailSubscribeModal}
                onClose={handleEmailSubscribeClose}
                onSubmit={handleSubmit}
            />
        );
    }
    return null;
}
export default EmailSubscribeContainer;
