import React, { memo, useCallback, useEffect, useMemo } from 'react';

import { useSelector } from 'react-redux';

// bonnet

import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import { useDevice } from '@bonnet/next/device';

import {
    Carousel,
    Chip,
    Glyphicon,
} from 'reaxl';
import { myRecentSearchClick, myRecentSearchImpression, sendClick, sendImpressions } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';

import {
    recentSearchDuck,
} from '@/ducks';

import useSrpNavigation from '@/hooks/useSrpNavigation';

function FilterChip({ heading, onClick }) {
    return heading
    && (
        <Chip
            data-cmp="filterChip"
            className="display-flex flex-column"
            label={(
                <span>
                    <Glyphicon
                        glyph="search"
                        className="margin-right-2 text-accent"
                    />
                    <span>
                        {heading}
                    </span>
                </span>
            )}
            onClick={(event) => onClick(event)}
            uiContext="filter"
        />
    );
}

export function RecentSearchChipsContainer() {
    const {
        recent_searches_srp: [enableRecentSearches],
        recent_searches_new_placement: [enableRecentSearchesNewPlacement],
    } = useFeatures([
        'recent_searches_srp',
        'recent_searches_new_placement',
    ]);

    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);

    const navigateToSrp = useSrpNavigation();

    const options = useMemo(() => ({
        prevNextButtons: !isXs,
    }), [isXs]);

    const currentRecentSearches = useSelector(recentSearchDuck.selectors.getLatestRecentSearches, _isEqual);

    useEffect(() => {
        sendImpressions({
            name: myRecentSearchImpression,
        });
    }, []);

    const handleChipClick = useCallback(async (event, query = {}) => {
        event.preventDefault();

        sendClick(myRecentSearchClick, event, {
            filterValues: query,
            textContent: event.target.textContent,
        });

        if (query) {
            navigateToSrp({
                filtersValues: query,
                resetPagination: true,
                isNewSearch: true,
                action: 'replace',
            });
        }
    }, [navigateToSrp]);

    const renderChips = () => (
        <div
            data-cmp="recentSearchChips"
            className="display-flex flex-nowrap"
        >
            {currentRecentSearches.map((chip) => (
                <FilterChip
                    key={chip?.fullHeading}
                    onClick={(event) => handleChipClick(event, chip?.query)}
                    {...chip}
                />
            ))}

        </div>
    );

    return enableRecentSearches && currentRecentSearches.length > 0 && enableRecentSearchesNewPlacement ? renderChips() : (
        <div
            data-cmp="recentSearchChips"
            className="margin-bottom-3"
        >
            <Carousel
                initialIndex={0}
                lazyLoad={3}
                fullWidth={false}
                fullHeight
                fullWidthScroll
                visibilityThreshold={0.9}
                {...options}
            >
                {currentRecentSearches.map((chip) => (
                    <FilterChip
                        key={chip?.fullHeading}
                        onClick={(event) => handleChipClick(event, chip?.query)}
                        {...chip}
                    />
                ))}
            </Carousel>
        </div>
    );
}

export default memo(RecentSearchChipsContainer);
