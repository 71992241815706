import React from 'react';

import { useDispatch } from 'react-redux';

import { Text } from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { PromoCard } from 'reaxl-listing';

import { paymentsDuck } from '@/ducks';

function SyncMyWalletPlacement() {

    const dispatch = useDispatch();

    const openWalletModal = () => {
        dispatch(paymentsDuck.creators.updateMyWallet({
            displayModal: true,
            selectedPanelName: 'walletCentral',
        }));
    };

    const handleButtonClick = (e) => {
        openWalletModal();

        sendClick('myWalletSrpPlacementClick', e);
    };

    return (
        <PromoCard
            buttonText="Sync Now"
            buttonStyle={{ width: '90%' }}
            className="col-xs-12 col-sm-4 display-flex"
            data-cmp="walletSyncPlacement"
            handleButtonClick={handleButtonClick}
            heading="Sync My Wallet"
            iconName="wallet"
            iconClassnames="text-size-800 text-accent"
            subText={(
                <Text componentClass="div">
                    Easily transfer your settings across Autotrader, Kelley Blue Book® and hundreds of participating dealer websites using your My Wallet ID.
                </Text>
            )}
        />
    );
}

export default SyncMyWalletPlacement;
