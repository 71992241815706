import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { connect, useDispatch, useSelector } from 'react-redux';

import dynamic from 'next/dynamic';

import _chunk from 'lodash/chunk';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';

// bonnet
import { useDevice } from '@bonnet/next/device';

import {
    BrowserStorage,
    formattedNumber,
} from 'atc-js';

import { SubHeading } from 'reaxl';
import { brands, useBrand } from 'reaxl-brand';
import { useFeatures } from 'reaxl-features';
import {
    InventoryListingPlaceholder,
    InventoryListingV2,
} from 'reaxl-listing';

import configureListingTiles from '@/utilities/configureListingTiles';
import getCpoByMake from '@/utilities/getCpoByMake';
import useInventoryListingProps from '@/utilities/useInventoryListingProps';

import {
    authDuck,
    cpoContentDuck,
    userDuck,
} from '@/ducks';

import {
    srpAdsDuck,
    srpFiltersDuck,
    srpLocationTypeDuck,
    srpNewCarBoostDuck,
    srpPaginationDuck,
    srpPrimeSpotlightDuck,
    srpResultsDuck,
    srpSpotlightDuck,
    srpSupplementalDuck,
} from '@/ducks/srp';

import LazyComponent from '@/components/LazyComponent';

import EVArticlesWrapper from './EVArticlesWrapper';
import usePremiumSpotlightUrgencyDrivers from './hooks/usePremiumSpotlightUrgencyDrivers';
import InventoryBoostContainer from './InventoryBoostContainer';
import InventorySpotlightContainer from './InventorySpotlightContainer';
import ListingsLinerAds from './ListingsLinerAds';
import MyWalletGridContainer from './MyWalletGridContainer';
import MyWalletPlacementContainer from './MyWalletPlacementContainer';
import PremiumSpotlightContainer from './PremiumSpotlightContainer';
import PrivateSellerPlacementContainer from './PrivateSellerPlacementContainer';
import SellYourCarPlacementContainer from './SellYourCarPlacementContainer';
import SimilarInventoryContainerPlaceholder from './SimilarInventoryContainerPlaceholder';
import SRPDealerDiffModalWrapper from './SRPDealerDiffModalWrapper';
import SRPJumpLink from './SRPJumpLink';
import SRPListingCollection from './SRPListingCollection';
import SyncMyWalletPlacement from './SyncMyWalletPlacement';
import WalletInputCardContainer from './WalletInputCardContainer';

// lazy
export const PreorderSearchContainer = dynamic(() => import(
    /* webpackChunkName: "PreorderSearchContainer" */
    '@/containers/srp/PreorderSearchContainer'
), {
    ssr: false,
    loading: () => (
        <InventoryListingPlaceholder
            className="col-xs-12 col-sm-4"
            data-cmp="inv-placeholder-preorder"
            key="preorder-inventory-placeholder"
        />
    ),
});
export const CertifiedPreOwnedContainer = dynamic(() => import(
    /* webpackChunkName: "CertifiedPreOwnedContainer" */
    '@/containers/srp/CertifiedPreOwnedCard'
), {
    ssr: false,
    loading: () => (
        <InventoryListingPlaceholder
            className="col-xs-12 col-sm-4"
            data-cmp="inv-placeholder-cpoCard"
            key="inventory-placeholder-cpoCard"
            portrait
        />
    ),
});
export const SupplementalSearchContainer = dynamic(() => import(
    /* webpackChunkName: "SupplementalSearchContainer" */
    '@/containers/srp/SupplementalSearchContainer'
), {
    ssr: false,
    loading: () => (
        Array(6).fill().map((_, index) => (
            <InventoryListingPlaceholder
                className="col-xs-12 col-sm-4"
                data-cmp="inv-placeholder-supplemental"
                key={`supplemental-placeholder-${index}`}
            />
        ))
    ),
});
export const SimilarInventoryContainer = dynamic(() => import(
    /* webpackChunkName: "SimilarInventoryContainer" */
    '@/containers/srp/SimilarInventoryContainer'
), {
    ssr: false,
    loading: () => (<SimilarInventoryContainerPlaceholder />),
});
export const SpotlightCarouselContainer = dynamic(() => import(
    /* webpackChunkName: "SpotlightCarouselContainer" */
    '@/containers/srp/SpotlightCarouselContainer'
), { ssr: false });

/*
 * render list of inventory listings as spotlights, regular and supplemental listings
 */
function InventorySearchResultsContainerComponent({
    adsLazyLoadingOffset,
    certifiedSponsor,
    filtersValues,
    isLastPage,
    listings = [],
    resultCount = 0,
    currentPage,
    resultsPerPage,
    searchRadius,
    isLoggedIn,
}) {

    const dispatch = useDispatch();
    const { brand, isBrand } = useBrand();
    const isKbbBranded = isBrand(brands.KBB_BRAND);

    const userLocation = useSelector(userDuck.selectors.getCityState);
    const spotlights = useSelector(srpSpotlightDuck.selectors.getActiveInventory, _isEqual);
    const premiumSpotlights = useSelector(srpPrimeSpotlightDuck.selectors.getActiveInventory, _isEqual);
    const isResultsLoading = useSelector(srpResultsDuck.selectors.getResultLoading);
    const boostListings = useSelector(srpNewCarBoostDuck.selectors.getAvailableInventory, _isEqual);
    const supplementalResultsCount = useSelector(srpSupplementalDuck.selectors.getResultCount);
    const supplementalResultsLoaded = useSelector(srpSupplementalDuck.selectors.getLoadedCount);
    const supplementalInitialLoad = useSelector(srpSupplementalDuck.selectors.getInitialLoad);
    const supplementalLoading = useSelector(srpSupplementalDuck.selectors.getLoading);
    const isFiltersVisible = useSelector(srpFiltersDuck.selectors.getFiltersVisible);

    const leadProfile = useSelector(userDuck.selectors.getLeadProfile);
    const makeFilterValues = useSelector(srpFiltersDuck.selectors.getMakeFilterValues);
    const listingTypeFilterValues = useSelector(srpFiltersDuck.selectors.getListingTypeValues);

    const urgencyDriverData = usePremiumSpotlightUrgencyDrivers({ spotlight: premiumSpotlights[0] });

    const previousListingsRef = useRef(listings);
    const isNationalSRP = useSelector(srpLocationTypeDuck.selectors.getDuckState);

    useEffect(() => {
        // TODO: refactor this to not use the listings and instead usePageEvent for changing on a "new page"
        if (previousListingsRef.current !== listings) {
            previousListingsRef.current = listings;
        }
    }, [listings]);

    const changeWalletPlacement = previousListingsRef.current !== listings;

    useEffect(() => {
        if (userDuck && !leadProfile) {
            dispatch(userDuck.creators.setLeadProfile());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        accelerate: [, {
            enable_listing_badge: enableListingBadge,
        }],
        disable_supplemental_listings: [disableSupplementalListings], // optimizely switch
        disable_similar_listings_srp: [disableSimilarListings],
        enable_new_car_boost: [enableNewCarBoost],
        spotlight: [isSpotlightEnabled], // feature config
        supplemental: [supplementalEnabled],
        brand: [, { base_url: baseUrl }],
        override_carfax_tile: [isOverrideCarfaxTileEnabled, { partner }],
        my_wallet: [enableMyWallet],
        premium_spotlight: [enablePremiumSpotlight, {
            peekaboo_placement: peekabooPlacementHigher,
            peekaboo_placement_10: peekabooPlacementLower,
            multi_carousel_3: enableMultiCarousel3,
            multi_carousel_9: enableMultiCarousel9,
        }],
        schema: [, { listing_type: schemaType, vehicle_feature_description: featureDescription, vehicle_price_description: priceDescription }],
        srp_promo_cards: [enableSrpPromoCards],
        srp_wallet_grid_promo_card: [enableNewWalletPromoCard],
        remove_carfax_tile: [removeCarfaxTile],
        oem_cpo_srp: [, { listing_card: isOEMListingCard }],
        srp_bottom_lazy_threshold: [, { defaultThreshold }],
    } = useFeatures([
        'accelerate',
        'disable_spotlights',
        'disable_pkg_and_specs',
        'disable_supplemental_listings',
        'disable_similar_listings_srp',
        'enable_new_car_boost',
        'spotlight',
        'supplemental',
        'brand',
        'override_carfax_tile',
        'my_wallet',
        'premium_spotlight',
        'schema',
        'srp_promo_cards',
        'srp_third_spotlight_index',
        'srp_wallet_grid_promo_card',
        'remove_carfax_tile',
        'oem_cpo_srp',
        'srp_bottom_lazy_threshold',
    ]);

    const [showSimilarListings, setShowSimilarListings] = useState(false);

    const hasSearchRadius = !!searchRadius;
    let carouselThreeSpotlightListings = [...premiumSpotlights];
    let carouselNineSpotlightListings = [...premiumSpotlights];

    const shouldLoadSimilarListings = !isNationalSRP && hasSearchRadius;

    useEffect(() => {
        if (!shouldLoadSimilarListings) {
            return;
        }

        if (showSimilarListings && supplementalResultsCount === 0 && supplementalResultsLoaded === 0 && supplementalInitialLoad) {
            // detect that srpSupplementalDuck has been cleared on SupplementalSearchContainer useEffect
            setShowSimilarListings(false);
        } else if (!showSimilarListings && !supplementalLoading && !supplementalInitialLoad && !isResultsLoading) {
            const isLowResultCount = resultCount + supplementalResultsCount <= 25;
            const supplementalListingsRendered = supplementalResultsCount > 0 && ((supplementalResultsLoaded >= supplementalResultsCount) || supplementalResultsLoaded === 100);
            const noSupplementalLoaded = !supplementalInitialLoad && !supplementalLoading && supplementalResultsCount === 0;
            const isSupplementalDoneLoading = supplementalListingsRendered || noSupplementalLoaded;
            if (!disableSimilarListings && isLowResultCount && isSupplementalDoneLoading && hasSearchRadius) {
                setShowSimilarListings(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supplementalResultsCount, supplementalResultsLoaded, supplementalInitialLoad, supplementalLoading, resultCount, showSimilarListings, isResultsLoading, isNationalSRP, shouldLoadSimilarListings]);

    const schemaTemplate = {
        featureDescription,
        priceDescription,
    };

    const device = useDevice();
    const notXS = _get(device, 'greaterThan.xs', false);

    // Clean up eventBus and script tag when component unmount
    useEffect(() => {
        ['psx.listingActions.openMakeOffer', 'psx.listingActions.makeOfferModalIsLoaded'].forEach(window.caiEventBus.off);
        document.querySelector('script[src*="static.kbb.com/psx-listing-actions"]')?.remove();
    }, []);

    // Spotlight experiments
    const enableSpotlightCarousel = enablePremiumSpotlight && premiumSpotlights.length > 2;
    const showPeekabooHigher = enablePremiumSpotlight && peekabooPlacementHigher && !_isEmpty(urgencyDriverData);
    const showPeekabooLower = enablePremiumSpotlight && peekabooPlacementLower && !_isEmpty(urgencyDriverData);
    const showPeekabooSpotlight = showPeekabooHigher || showPeekabooLower;
    const showTwoCarousels = enablePremiumSpotlight && enableMultiCarousel3 && enableMultiCarousel9;

    // Wallet sync placement logic
    const walletSyncCache = new BrowserStorage('LAST_WALLET_SYNC', {
        persist: true,
    });

    const lastWalletSync = walletSyncCache.getItem() || 0;
    const today = Date.now();
    const oneDay = 24 * 60 * 60 * 1000;

    const showWalletSyncCard = (today - Date.parse(lastWalletSync)) > oneDay;

    // Show supplemental results condition
    const showSupplementalResults = isLastPage && hasSearchRadius;

    const cpoData = useMemo(() => getCpoByMake(makeFilterValues, certifiedSponsor), [makeFilterValues, certifiedSponsor]);
    const hasCPOData = !_isEmpty(cpoData);
    const displayCPOCTA = hasCPOData && !listingTypeFilterValues.includes('CERTIFIED');
    const isMakeSelected = makeFilterValues.length > 0;
    // Show CPO Card has CPO make, and cpo not selected and display the latest selected make card only.
    const showCPOCard = !!isMakeSelected && displayCPOCTA && isOEMListingCard && hasCPOData;

    // Retrieve saved inventory ids and set them in the inventory reducer
    const initializeUserInfo = useCallback(() => {
        dispatch(userDuck.creators.hydrateUserInventory({ isLoggedIn, onLoggedInStateReady: () => { } }));
    }, [dispatch, isLoggedIn]);

    // Retrieve recently viewed inventory ids and set them in the inventory reducer
    const initializeRecentInventory = useCallback(() => {
        dispatch(userDuck.creators.hydrateRecentInventory({ isLoggedIn, onLoggedInStateReady: () => { } }));
    }, [dispatch, isLoggedIn]);

    useEffect(() => {
        initializeRecentInventory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*
      * This useEffect hook is intended to fire only on component mount
      * and will request a fourth spotlight if necessary as well as
      * fire all initial page-loaded spotlight listings
      */
    useEffect(() => {
        // Initialize saved listing and other user data
        // Only call this function on KBB
        if (isKbbBranded) {
            initializeUserInfo();
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getListingProps = useInventoryListingProps();

    /**
     * render a special listing that is not part of the base listings call
     *
     * @param listingIndex index at which the spotlight should be added
     * @param listingCategory type of special listing
     * @returns {*} the markup with spotlight(s) being injected in the results
     */

    const renderSpecialListing = (listingIndex, listingCategory = 'spotlight') => {
        const listingProps = {
            baseUrl,
            schemaTemplate,
            schemaType,
            userLocation,
            currentPage,
            listingIndex,
            brand,
            certifiedSponsor,
            isOverrideCarfaxTileEnabled,
            partner,
            removeCarfaxTile,
        };

        if (isFiltersVisible) {
            return (
                <InventoryListingPlaceholder
                    className="col-xs-12 col-sm-4"
                    data-cmp="inv-placeholder-lstg"
                    key={`${listingCategory}-placeholder-${listingIndex}`}
                    id={`${listingCategory}-placeholder-${listingIndex}`}
                />
            );
        }

        if (listingCategory === 'spotlight' && !!isSpotlightEnabled) {
            return (
                <InventorySpotlightContainer
                    key={`spotlight-${listingIndex}`}
                    {...listingProps}
                />
            );
        }

        if (listingCategory === 'boost') {
            return (
                <InventoryBoostContainer
                    key={`boost-${listingIndex}`}
                    {...listingProps}
                />
            );
        }

        if (listingCategory === 'peekaboo') {
            return (
                <PremiumSpotlightContainer
                    premiumSpotlight={premiumSpotlights[0]}
                    urgencyDriverData={urgencyDriverData}
                    {...listingProps}
                />
            );
        }

        return () => { };
    };

    const listingsWithTiles = configureListingTiles({
        brand,
        certifiedSponsor,
        items: listings,
        isOverrideCarfaxTileEnabled,
        partner,
        tier: 'listing',
        removeCarfaxTile,
    });

    const renderResultsCount = (count) => (
        <SubHeading
            componentClass="h2"
            className="padding-bottom-4"
            size={500}
            data-cmp="resultsCount"
        >
            {formattedNumber({ value: count }) + ' Results'}
            <SRPJumpLink />
        </SubHeading>
    );

    const renderEVArticleCarousel = (
        <EVArticlesWrapper />
    );

    // TODO: maybe just set to invisible instead of unmounting when there are not enough listings?
    const renderLinerAd = (index) => (
        <div
            className="col-xs-12 margin-bottom-5"
            key={`liner-ad-${index}`}
        >
            <ListingsLinerAds
                adsLazyLoadingOffset={adsLazyLoadingOffset}
                index={index}
                notXs={notXS}
            />
        </div>
    );

    const renderSupplementalListings = !!supplementalEnabled && !disableSupplementalListings && (
        <SupplementalSearchContainer
            listingsWithTilesLength={listingsWithTiles.length}
            showSupplementalResults={showSupplementalResults}
            isLastPage={isLastPage}
        />
    );

    const searchResultsListings = listingsWithTiles?.map((listing, index) => {
        const paginatedListingIndex = (index + 1) + ((currentPage - 1) * resultsPerPage);

        if (!enableListingBadge) {
            listing.accelerate = false;
        }

        if (isResultsLoading || isFiltersVisible) {
            return (
                <InventoryListingPlaceholder
                    className="col-xs-12 col-sm-4"
                    data-cmp="inv-placeholder-lstg"
                    key={`inventory-placeholder-${index}`}
                    id={`inventory-placeholder-${index}`}
                />
            );
        }

        return (
            <div
                className="col-xs-12 col-sm-4 display-flex"
                key={`listing-${index}`}
                id={`listing-${index}`}
            >
                <InventoryListingV2 {...getListingProps('listings', listing, index, paginatedListingIndex)} />
            </div>
        );
    });

    /*
     * Inject 4 Spotlights into the SRP results if available:
     * 2 Premium spotlights between the Alpha and the search results
     * 1 Premium spotlight after the 5th listing of the search results
     * 1 Non-Premium spotlight after the 20th listing. Show a Featured over a Standard spotlight if there are the same
     *  or more featured than standard organic listings
     */
    /* eslint-disable no-unused-expressions*/
    // insert spotlights at specific indexes
    [0, 1, 2, 23].forEach((position, index) => {
        spotlights?.[index]?.id && searchResultsListings.splice(position, 0, renderSpecialListing(index));
    });

    /*
    * Inject My Wallet promo grid placement at spot #4
        will be injected in a row separately when higher peekaboo variant is on
    */
    if (enableNewWalletPromoCard) {
        searchResultsListings.splice(3, 0, <WalletInputCardContainer key="WalletInputCardContainer" />);
    }

    if (showPeekabooHigher) {
        premiumSpotlights[0] && searchResultsListings.splice(4, 0, renderSpecialListing(4, 'peekaboo'));
        searchResultsListings.splice(5, 0, null);
    }

    if (showPeekabooLower) {
        premiumSpotlights[0] && searchResultsListings.splice(8, 0, renderSpecialListing(8, 'peekaboo'));
        searchResultsListings.splice(9, 0, null);
    }

    /*
     * Inject 2 New Car Boost listings if available
     * first one at index 6 or the first listing in the 3rd row on desktop
     * second at index 21 if there are 75 or more results per page
     */
    if (enableNewCarBoost) {
        if (boostListings[0]) {
            searchResultsListings.splice(6, 0, renderSpecialListing(0, 'boost'));
            // Ensure there is enough listing slots to fill the row before splicing into results
        } else if (searchResultsListings.length > 8) {
            if (showWalletSyncCard) {
                searchResultsListings.splice(6, 0, <SyncMyWalletPlacement key="SyncWalletPromo_6" />);
            } else {
                searchResultsListings.splice(6, 0, <SellYourCarPlacementContainer key="SellYourCarPromo_6" />);
            }
        }

        if (resultsPerPage >= 75 && resultCount >= 75) {
            boostListings[1] && searchResultsListings.splice(22, 0, renderSpecialListing(1, 'boost'));
        }
    }

    /*
         * Inject My Wallet placement at spot #15
         */
    if (searchResultsListings.length > 14 && enableSrpPromoCards) {
        searchResultsListings.splice(
            14,
            0, (
                <MyWalletPlacementContainer
                    key="walletPlacementContainer"
                    changePlacement={changeWalletPlacement}
                />
            )
        );
    }

    // Conditions for spot 23

    const isPrivateSellerPage = filtersValues?.sellerType?.length === 1 && filtersValues?.sellerType?.includes('p');
    const showNewCarBoostListing = enableNewCarBoost && boostListings[0];

    // Inject CPO Card at spot #23
    if (showCPOCard && searchResultsListings.length > 22) {
        searchResultsListings.splice(
            22,
            0, (
                <CertifiedPreOwnedContainer
                    data={cpoData}
                    makeFilterValues={makeFilterValues}
                />
            )
        );
    }

    /*
    * if on a private seller page, inject placement in spot #23, shows if cpo card does not
    */
    if (!showCPOCard && enableSrpPromoCards && isPrivateSellerPage) {
        searchResultsListings.splice(22, 0, <PrivateSellerPlacementContainer changePlacement={changeWalletPlacement} />);
    }
    /*
    * only show Sync wallet placement in spot 23 if new car boost is showing in spot 7, and no PSX or CPO placements
    */
    if (!showCPOCard && enableSrpPromoCards && !isPrivateSellerPage && showNewCarBoostListing && showWalletSyncCard) {
        searchResultsListings.splice(22, 0, <SyncMyWalletPlacement key="SyncWalletPlacement_22" />);
    }

    if (enableSrpPromoCards && !showCPOCard && (!showWalletSyncCard || !showNewCarBoostListing) && !isPrivateSellerPage && searchResultsListings.length > 22) {
        searchResultsListings.splice(22, 0, <SellYourCarPlacementContainer key="SellYourCarPromo_22" />);
    }

    if (showPeekabooSpotlight) {
        // remove peekaboo from carousel listings
        const newArray = [...premiumSpotlights];
        newArray.shift();

        carouselNineSpotlightListings = newArray;
        carouselThreeSpotlightListings = newArray;

    }

    if (showTwoCarousels) {
        // split spotlights between carousels
        if (premiumSpotlights.length > 5) {
            const arrayChunk = Math.ceil((premiumSpotlights.length) / 2);

            const premiumSpotlightsArray = [...premiumSpotlights];

            carouselThreeSpotlightListings = premiumSpotlightsArray.slice(0, arrayChunk);
            carouselNineSpotlightListings = premiumSpotlightsArray.slice(arrayChunk, arrayChunk + premiumSpotlightsArray.length);
        }
    }

    const renderSpotlightCarouselIndex3 = enableSpotlightCarousel && enableMultiCarousel3 && (
        <LazyComponent
            key="spotlight-carousel-placeholder-3"
            height={435}
            componentProps={{ className: 'margin-bottom-5 col-xs-12' }}
        >
            <SpotlightCarouselContainer premiumSpotlights={carouselThreeSpotlightListings} />
        </LazyComponent>
    );
    const renderSpotlightCarouselIndex9 = enableSpotlightCarousel && enableMultiCarousel9 && premiumSpotlights.length > 5 && (
        <LazyComponent
            key="spotlight-carousel-placeholder-9"
            height={435}
            componentProps={{ className: 'margin-bottom-5 col-xs-12' }}
        >
            <SpotlightCarouselContainer premiumSpotlights={carouselNineSpotlightListings} />
        </LazyComponent>
    );

    // wrap every listing after the 3rd one in LazyComponent
    const searchResultsLazyListings = [];
    searchResultsListings.forEach((listing, index) => {
        if (index < 3 || listing?.key === 'myWalletPromoCard') {
            searchResultsLazyListings.push(listing);
        } else {
            searchResultsLazyListings.push(
                <LazyComponent
                    componentClass={InventoryListingPlaceholder}
                    componentProps={{ className: 'col-xs-12 col-sm-4' }}
                    key={`inventory-placeholder-${index}`}
                >
                    {listing}
                </LazyComponent>
            );
        }
    });

    // chunk all search results listings in sets of 3 representing each row [[item, item, item], [item, item, item], ...]
    const chunkedListingsSet = _chunk(searchResultsLazyListings, 3);

    // insert 3-column-wide components at specific indexes of chunkedListingsSet
    searchResultsListings.length > 6 && chunkedListingsSet.splice(1, 0, [renderSpotlightCarouselIndex3]);
    searchResultsListings.length > 6 && chunkedListingsSet.splice(3, 0, renderLinerAd(0));
    searchResultsListings.length > 6 && chunkedListingsSet.splice(5, 0, renderSpotlightCarouselIndex9);
    searchResultsListings.length > 14 && chunkedListingsSet.splice(7, 0, [renderLinerAd(1), renderEVArticleCarousel]);
    searchResultsListings.length > 19 && chunkedListingsSet.splice(10, 0, renderLinerAd(2));
    searchResultsListings.length > 33 && chunkedListingsSet.splice(13, 0, renderLinerAd(3));
    searchResultsListings.length > 36 && chunkedListingsSet.splice(16, 0, renderLinerAd(4));

    const showWalletGrid = currentPage === 1 || resultCount === 0;
    const renderMyWalletGrid = (enableMyWallet && showWalletGrid) && <MyWalletGridContainer uiContext="default" />;

    const renderResultsLayout = (
        <div className="row display-flex flex-wrap margin-top-3">
            {chunkedListingsSet.slice(0, 1).flat().map((item) => item)}

            {renderMyWalletGrid}

            {chunkedListingsSet.slice(1, chunkedListingsSet.length).flat().map((item) => item)}

            {isLastPage && <PreorderSearchContainer />}

            {isLastPage && renderSupplementalListings}

            {shouldLoadSimilarListings && isLastPage && (showSimilarListings || (!supplementalEnabled && resultCount < 25)) && (
                <LazyComponent
                    threshold={defaultThreshold}
                    fallback={(<SimilarInventoryContainerPlaceholder />)}
                >
                    <SimilarInventoryContainer
                        searchRadius={searchRadius}
                        isLastPage={isLastPage}
                    />
                </LazyComponent>
            )}

            <SRPListingCollection />
        </div>
    );

    // render liner ads outside of a loading indicator in order to prevent ads unmounting
    return (
        <div data-qaid="cntnr-listings">

            {renderResultsCount(resultCount)}

            {renderResultsLayout}

            <SRPDealerDiffModalWrapper />

            <lead-generation
                layout="srpListing"
                is-director
            />
        </div>
    );
}

export const InventorySearchResultsContainer = memo(
    InventorySearchResultsContainerComponent,
    (oldProps, newProps) => _isEqual(newProps.listings, oldProps.listings) && _isEqual(newProps.filtersValues, oldProps.filtersValues)
);

const mapStateToProps = (state) => {
    const currentPage = srpPaginationDuck.selectors.getCurrentPage(state);
    let numRecords = srpPaginationDuck.selectors.getNumRecords(state);

    const listings = srpResultsDuck.selectors.getActiveInventory(state);
    const searchRadius = srpFiltersDuck.selectors.getSelectedSearchRadius(state);
    const resultCount = srpResultsDuck.selectors.getResultsCount(state);
    const isLastPage = currentPage >= Math.ceil(resultCount / numRecords);

    const filtersValues = srpFiltersDuck.selectors.getRequestValues(state);
    const isLoggedIn = authDuck.selectors.isLoggedIn(state);
    const certifiedSponsor = cpoContentDuck.selectors.selectCertifiedContentSimple(state);
    const resultPerPage = state.brand === brands.FORD_BRAND && numRecords === 25 ? numRecords = 30 : numRecords;

    return {
        currentPage,
        resultsPerPage: resultPerPage,
        adsLazyLoadingOffset: srpAdsDuck.selectors.getAdsLazyLoadingOffset(state),
        certifiedSponsor,
        listings,
        resultCount,
        searchRadius,
        isLastPage,
        filtersValues,
        isLoggedIn,
    };
};

export default connect(mapStateToProps)(InventorySearchResultsContainer);
