import React, { useEffect } from 'react';

import { Text } from 'reaxl';
import { sendImpressions } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';

import { inventoryImpressions } from '@/analytics/srpAnalyticsHandlers';

import SrpListingAlert from '@/components/SrpListingAlert/index.js';

import usePremiumSpotlightUrgencyDrivers from './hooks/usePremiumSpotlightUrgencyDrivers';

function SpotlightAlertContainer({
    premiumSpotlightListing,
    ...rest
}) {

    const {
        premium_spotlight: [, { top_placement: enableTopPlacement }],
    } = useFeatures(['premium_spotlight']);

    const {
        type: urgencyDriverType,
        header: urgencyDriverHeader,
        subText: urgencyDriverSubText,
    } = usePremiumSpotlightUrgencyDrivers({ spotlight: premiumSpotlightListing });

    const buildIncentiveTitle = () => (
        <span>
            <Text
                style={{ color: '#000' }}
                weight="bold"
            >
                {urgencyDriverHeader}
            </Text>
            &nbsp;
            <Text
                color="success"
                weight="bold"
            >
                {urgencyDriverSubText}
            </Text>
        </span>
    );

    const buildReducedTitle = () => (
        <Text
            color="success"
            weight="bold"
        >
            {urgencyDriverHeader}
        </Text>
    );

    const buildNewlyListedTitle = () => (
        <Text
            color="accent"
            weight="bold"
        >
            {urgencyDriverHeader}
        </Text>
    );

    const buildNumViewedTitle = () => (
        <span>
            <Text
                color="accent"
                weight="bold"
            >
                {urgencyDriverHeader}
            </Text>
            &nbsp;
            <Text
                style={{ color: '#000' }}
                weight="bold"
            >
                {urgencyDriverSubText}
            </Text>
        </span>
    );

    let alertTitle;
    let impressionCmp;

    switch (urgencyDriverType) {
        case 'incentive':
            alertTitle = buildIncentiveTitle();
            impressionCmp = 'incentives';
            break;
        case 'reduced':
            alertTitle = buildReducedTitle();
            impressionCmp = 'price-reduced';
            break;
        case 'newlylisted':
            alertTitle = buildNewlyListedTitle();
            impressionCmp = 'newly-listed';
            break;
        case 'numviewed':
            alertTitle = buildNumViewedTitle();
            impressionCmp = 'number-viewed';
            break;
        default:
    }

    useEffect(() => {
        if (premiumSpotlightListing && impressionCmp && enableTopPlacement) {
            sendImpressions({
                name: inventoryImpressions,
                data: {
                    cmp: impressionCmp,
                    selectedItemIds: [premiumSpotlightListing.id],
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [premiumSpotlightListing?.id, sendImpressions]);

    return (
        <SrpListingAlert
            alertTitle={alertTitle}
            isDismissible={false}
            listing={premiumSpotlightListing}
            urgencyDriverLabel={impressionCmp}
            analyticsContext="top-spv"
            {...rest}
        />
    );
}

export default SpotlightAlertContainer;
