import React, { memo } from 'react';

import { useSelector } from 'react-redux';

import dynamic from 'next/dynamic';

import { useFeatures } from 'reaxl-features';

import getElectricShopper from '@/utilities/getElectricShopper';

import { queryDuck } from '@/ducks';

import { srpFiltersDuck } from '@/ducks/srp';

import PersonalizationEngineModule from '@/modules/PersonalizationEngineModule';

import LazyComponent from '@/components/LazyComponent';

import EVArticlesPlaceholder from './EVArticlesPlaceholder';

export const EVArticlesCarouselContainer = dynamic(() => import(
    /* webpackChunkName: "EVArticlesCarouselContainer" */
    '@/containers/srp/EVArticlesCarouselContainer'
), {
    ssr: false,
    loading: () => (
        <EVArticlesPlaceholder />
    ),
});

function EVArticlesWrapper() {
    const {
        ev_articles: [isEVArticlesEnabled, { evArticlesList1: isArticleList1Enabled, evArticlesList2: isArticleList2Enabled }],
    } = useFeatures([
        'ev_articles',
    ]);

    const query = useSelector(queryDuck.selectors.getDuckState);
    const filtersOptions = useSelector(srpFiltersDuck.selectors.getFiltersOptions);
    const filtersValues = useSelector(srpFiltersDuck.selectors.getFiltersValues);

    const peData = useSelector(PersonalizationEngineModule.duck.selectors.selectPersonalization);

    const isElectric = getElectricShopper({ filtersOptions, filtersValues, peData, query });

    const showEVArticleCarousel = isEVArticlesEnabled && (isArticleList1Enabled || isArticleList2Enabled) && isElectric;

    return showEVArticleCarousel && (
        <LazyComponent
            componentProps={{ className: 'col-xs-12' }}
            fallback={<EVArticlesPlaceholder />}
            height={136}
            key="ev-articles-carousel"
        >
            <div className="col-xs-12 margin-bottom-5">
                <EVArticlesCarouselContainer />
            </div>
        </LazyComponent>
    );
}

export default memo(EVArticlesWrapper);
