import React, { memo, useCallback } from 'react';

import { batch, connect, useDispatch, useSelector } from 'react-redux';

import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import { useDevice } from '@bonnet/next/device';

import { mapDispatchToActionProp } from '@atc/modular-redux';

import { sendClick } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';
import {
    FilteredResultsControls,
} from 'reaxl-filters';

import { srpSortDropDownClick } from '@/analytics/srpAnalyticsHandlers';

import inventorySortOptions from '@/config/sort';

import {
    compareListingsDuck,
    paymentsDuck,
} from '@/ducks';

import {
    srpActiveInteractionDuck,
    srpDisableScrollDuck,
    srpFiltersDuck,
    srpSortDuck,
} from '@/ducks/srp';

import useSrpNavigation from '@/hooks/useSrpNavigation';

function FilterMobileControlsContainer({
    actions: {
        setSort,
        showFilters,
        updateMyWallet = () => { },
    },
    className,
    renderCompareToggle,
    onFilterClick = () => { },
    isStickyHeader,
}) {
    const dispatch = useDispatch();
    const navigateToSrp = useSrpNavigation();
    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);
    const lessThanMd = _get(device, 'lessThan.md', false);

    const {
        compare: [showCompare],
        my_wallet: [enableMyWallet],
        remove_payments_toggle: [removePaymentsToggle, {
            wallet_text: optimizelyWalletText,
        }],
    } = useFeatures([
        'compare',
        'my_wallet',
        'remove_payments_popover',
        'remove_payments_toggle',
    ]);

    const currentSort = useSelector(srpSortDuck.selectors.getDuckState, _isEqual);

    // do not update selected filter values until the page is done loading
    const filters = useSelector(srpFiltersDuck.selectors.getSelectedFilterChips, _isEqual);
    const filterCount = filters.length;

    const sortData = { ...inventorySortOptions, value: currentSort };

    const handleSortChange = useCallback(async (event) => {
        const sortType = _get(event, 'target.value', null) || event;

        await batch(() => {
            // allow the page to scroll to top on page change
            dispatch(srpDisableScrollDuck.creators.setFalse());
            // set sort based on target value
            dispatch(setSort(sortType));
        });
        sendClick(srpSortDropDownClick, event, {
            sortType,
        });
        navigateToSrp({ resetPagination: true, isNewSearch: false });
    }, [dispatch, navigateToSrp, setSort]);

    const handleWalletClick = (event) => {
        updateMyWallet({ displayModal: true });
        sendClick('myWalletMobileToolbarClick', event);
    };

    const handleFilterClick = () => {
        onFilterClick();
        showFilters();
    };

    return (
        <FilteredResultsControls
            className={className}
            sortData={sortData}
            onFilterClick={handleFilterClick}
            onSortByChange={handleSortChange}
            onWalletClick={handleWalletClick}
            renderCompareToggle={renderCompareToggle}
            filterCount={filterCount}
            showCompare={showCompare}
            showWallet={(enableMyWallet || isXs)}
            showFilter={(showCompare && isXs) || lessThanMd}
            paymentsDuck={paymentsDuck}
            optimizelyWalletText={removePaymentsToggle ? optimizelyWalletText : undefined}
            isStickyHeader={isStickyHeader}
        />
    );
}

const mapDispatchToProps = mapDispatchToActionProp({
    addListingToCompare: compareListingsDuck.creators.addToCompare,
    setActiveInteraction: srpActiveInteractionDuck.creators.setKeys,
    setSort: srpSortDuck.creators.setSort,
    showFilters: srpFiltersDuck.creators.showFilters,
    fetchCompareListings: compareListingsDuck.creators.loadListingsData,
    updateMyWallet: paymentsDuck.creators.updateMyWallet,
    updateMyWalletInteractions: paymentsDuck.creators.updateMyWalletInteractions,
});

export default connect(null, mapDispatchToProps)(memo(FilterMobileControlsContainer));
