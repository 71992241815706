import React, { memo } from 'react';

import { batch } from 'react-redux';

import Error from 'next/error';

import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import { withCtxMiddleware } from '@bonnet/next/ctx-middleware';
import { withCtxAbortOnStatusCode } from '@bonnet/next/ctx-middleware/with-ctx-abort-on-status-code';
import { withBonnetPage } from '@bonnet/next/page';

import { pageNames } from '@atc/bonnet-paths';

import { getScaledImageSource } from 'atc-js';

import { StickyContainer } from 'reaxl';
import { atcAuthModule } from 'reaxl-auth';
import { useFeatures } from 'reaxl-features';

import { imageSizes } from 'axl-config';

import getSRPPrimaryImagesFromCtx from '@/utilities/getSRPPrimaryImagesFromCtx';
import useHashScroll from '@/utilities/useHashScroll';
import usePauseAudioEye from '@/utilities/usePauseAudioEye';

import campaignConfigData from '@/config/campaignConfigs';

import {
    addSearchResultsPageData,
    ctxPresetFindCar,
    dispatchSearchResultsPageData,
    withConsumerRatings,
    withCustomLeadRouting,
    withDataIslandDispatcher,
    withInterestRates,
    withPaymentServices,
    withPreloadHeader,
    withSelfServiceSeo,
    withWalletConfigs,
} from '@/context-middleware';

import {
    srpFetchCompositeJSON,
    withBreadcrumbPaths,
    withCampaignCookie,
    withCrawlPath,
    withKbbEditorialFetcher,
    withNoResultsSRP,
    withPrivateSellerRadiusBoost,
    withRequestOptions,
    withSavedRecentSearch,
    withSEOContent,
    withSrpQueryFixes,
} from '@/context-middleware/srp';

import * as srpDucks from '@/ducks/srp';
import { vdpActiveInteractionDuck, vdpDealerDiffDuck, vdpModelInfoDuck, vdpResultsDuck } from '@/ducks/vdp';

import {
    AlphaModule,
    CanonicalUrlModule,
    CookieConsentModule,
    ParsedQueryModule,
    PersonalizationEngineModule,
} from '@/modules';

import { CommonHeadContainer, PersonalizationEngineContainer } from '@/containers';

import SearchResultsPageContainer from '@/containers/srp/SearchResultsPageContainer';
import SRPAnalytics from '@/containers/srp/SRPAnalytics';
import SrpPageEventsContainer from '@/containers/srp/SrpPageEventsContainer';

import JumpLink from '@/components/JumpLink/index.js';
import SrpError from '@/components/SrpError/index.js';

const { SEARCH_RESULTS } = pageNames;

const SearchResultsPage = memo(({
    openGraphImage,
    currentUrl,
    statusCode,
    showSrpError = false,
    query = {},
    totalBoostCount,
}) => {
    const {
        disable_personalization_engine: [isPersonalizationEngineDisabled],
    } = useFeatures([
        'disable_personalization_engine',
    ]);

    usePauseAudioEye();

    // offset of 100 lines up perfectly with sticky header, and also works nicely for desktop breakpoints
    useHashScroll({ offset: -100 });

    // if the srp has error from a fallback api call
    // then show an SrpError that allows the user to try again
    if (showSrpError) {
        return (
            <SrpError />
        );
    }

    if (statusCode) {
        return <Error statusCode={statusCode} />;
    }

    return (
        <>
            {!isPersonalizationEngineDisabled && <PersonalizationEngineContainer pageName={SEARCH_RESULTS} />}
            <CommonHeadContainer
                currentUrl={currentUrl}
                pageName={SEARCH_RESULTS}
                query={query}
                openGraphImage={openGraphImage}
            />
            <SRPAnalytics totalBoostCount={totalBoostCount} />
            <SrpPageEventsContainer />
            <JumpLink
                message="Skip to vehicle Listings"
                id="#srp-listings"
            />
            <StickyContainer data-qaid="cntnr-spdysrp">
                <SearchResultsPageContainer currentUrl={currentUrl} />
            </StickyContainer>
        </>
    );
}, (prev, next) => _isEqual(prev, next));

SearchResultsPage.getInitialProps = async (ctx) => {
    // Pre-init the srp
    // This really only benefits a subsequent reloading of the page (filter, pagination, etc)
    // TODO: BONNET - We may want to clear out listings, spotlights and alpha here as well
    // and replace with placeholders?
    const actions = [
        vdpModelInfoDuck.creators.clear(),
    ];

    batch(() => {
        actions.forEach((action) => {
            ctx.store.dispatch(action);
        });
    });

    // apply context middleware
    await withCtxMiddleware([
        ctxPresetFindCar({
            pageName: SEARCH_RESULTS,
            preFetchCtxMiddleware: [
                withSrpQueryFixes(),
                withSelfServiceSeo(),
                withCampaignCookie({ data: campaignConfigData }),
                withPrivateSellerRadiusBoost(),
                withRequestOptions(),
                withKbbEditorialFetcher(),
                ctx.withModuleCtxMiddleware({
                    batchDispatch: false,
                    fetchCompositeJSON: false,
                }),
                // AlphaModule.ctxMiddleware,
                withSEOContent(),
                addSearchResultsPageData(),
            ],
            fetcher: srpFetchCompositeJSON,
            dispatcher: dispatchSearchResultsPageData,
        }),
        withNoResultsSRP(),
        withCtxAbortOnStatusCode(),
        withCrawlPath(),
        withBreadcrumbPaths(),
        withInterestRates(),
        withWalletConfigs(),
        withPaymentServices(),
        withConsumerRatings(),
        withCustomLeadRouting(),
        withSavedRecentSearch(),
        withPreloadHeader(),
        withDataIslandDispatcher(),
    ], ctx);

    if (ctx.data.showSrpError) {
        ctx.res?.status(500);
        return {
            showSrpError: ctx.data.showSrpError,
        };
    }

    // if the current ctx contains a status code then we need return it
    if (ctx.statusCode) {
        return {
            statusCode: ctx.statusCode,
        };
    }

    const images = getSRPPrimaryImagesFromCtx(ctx);

    let openGraphImage;
    if (ctx?.data?.listings?.length > 0) {
        const primary = _get(images, 'primary', 0);
        const rawOpenGraphImage = _get(ctx, `data.listings[0].images.sources[${primary}].src`);
        openGraphImage = rawOpenGraphImage && getScaledImageSource({
            src: rawOpenGraphImage,
            ...imageSizes.openGraphImg,
        });
    }
    const totalBoostCount = ctx?.data?.totalBoostCount;

    return {
        images,
        openGraphImage,
        query: ctx.query,
        currentUrl: ctx.data.currentUrl,
        totalBoostCount,
    };
};

const { BonnetPageComponent } = withBonnetPage(SearchResultsPage, {
    reducers: [
        ...Object.values(srpDucks),
        vdpResultsDuck,
        vdpActiveInteractionDuck,
        vdpDealerDiffDuck,
    ],
    modules: [
        AlphaModule,
        CanonicalUrlModule,
        CookieConsentModule,
        ParsedQueryModule,
        atcAuthModule,
        PersonalizationEngineModule,
    ],
});

export default BonnetPageComponent;
