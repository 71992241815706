import React, { memo } from 'react';

import { deprecate } from 'react-is-deprecated';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import { createChainedFunction } from 'atc-js';

import {
    SponsoredText,
} from 'reaxl';

import { getAlphaClickType } from '@/utilities/getAlphaTaggingType';

import {
    srpActiveInteractionDuck,
} from '@/ducks/srp';

import { AlphaModule } from '@/modules';

import InventoryAlphaListing from '@/components/InventoryAlphaListing/index.js';
import InventoryCarousel from '@/components/InventoryCarousel/index.js';
import OwnerAlphaListing from '@/components/OwnerAlphaListing/index.js';

const renderCarousel = ({
    carouselSettings,
    lazyLoad,
    listings,
    owner,
    orientation,
    onSaveToggle,
    isCompared,
    isAlphaMarket,
    onCompareClick,
    onInventoryListingClick,
    onSettle,
    onViewOwnerInventoryClick,
    savedListingIds,
    showCompareButton,
    uiContext,
    ...rest
}) => {

    let carouselListings = listings;

    if (listings && listings.length > 12) {
        carouselListings = listings.slice(0, 12);
    }

    return (
        <InventoryCarousel
            {...rest}
            listings={carouselListings}
            clickType={getAlphaClickType(isAlphaMarket, false)}
            onInventoryListingClick={onInventoryListingClick}
            onSaveToggle={onSaveToggle}
            onSettle={onSettle}
            onViewOwnerInventoryClick={onViewOwnerInventoryClick}
            orientation={orientation}
            showFooter={false}
            savedListingIds={savedListingIds}
            uiContext={uiContext}
            lazyLoad={lazyLoad}
            loading={lazyLoad ? 'lazy' : 'eager'}
            carouselSettings={carouselSettings}
            showCompareButton={showCompareButton}
            onCompareClick={onCompareClick}
            isCompared={isCompared}
            className="padding-horizontal-2"
        />
    );
};

function AlphaShowcase({
    carouselSettings = {},
    emailOwnerProps = {},
    enableMyWallet,
    fireTelmetricsTag = () => { }, // deprecated
    isCompared = () => { },
    lazyLoad = false,
    listing = {},
    listingCTA = {},
    listings = [],
    listingsCount = 0,
    onAlphaWalletClick = () => { },
    onCollapsibleHeaderClick = () => { },
    onCompareClick = () => { },
    onDealerWebsiteClick = () => { },
    onGetDirectionsClick = () => { },
    onAlphaListingClick = () => { },
    onInventoryListingClick = () => { },
    onPaymentCTAClick,
    onPricingDetailClick = () => { },
    onSaveToggle = () => { },
    onSettle = () => { },
    onTileClick = () => { },
    onViewOwnerInventoryClick = () => { },
    orientation = 'landscape',
    owner = {},
    ownerTxtCTA = {},
    parentId,
    paymentsCTA = {},
    personalPaymentProps = {},
    phoneClickHandler = () => { },
    savedListingIds = [],
    showAlphaPaymentRibbon = {},
    showCompareButton = false,
    showInventory = false,
    title = '',
    uiContext = 'inventory',
    updateListingPayments = () => { },
    ...rest
}) {
    const showAlphaCarousel = _get(useSelector(srpActiveInteractionDuck.selectors.getDuckState), 'showAlphaCarousel', false);
    const isAlphaMarket = useSelector(AlphaModule.duck.selectors.isAlphaMarket);

    phoneClickHandler = createChainedFunction(phoneClickHandler, fireTelmetricsTag);

    const renderFooter = (
        showAlphaCarousel && renderCarousel({
            carouselSettings,
            listings,
            owner,
            orientation,
            onInventoryListingClick,
            onSettle,
            onViewOwnerInventoryClick,
            onSaveToggle,
            savedListingIds,
            showCompareButton,
            isCompared,
            isAlphaMarket,
            onCompareClick,
        })
    );

    const renderDealerAlpha = (
        <OwnerAlphaListing
            phoneClickHandler={phoneClickHandler}
            owner={owner}
            orientation={orientation}
            emailOwnerProps={emailOwnerProps}
            onInventoryListingClick={onInventoryListingClick}
            onGetDirectionsClick={onGetDirectionsClick}
            onTileClick={onTileClick}
            onViewOwnerInventoryClick={onViewOwnerInventoryClick}
            parentId={parentId}
            uiContext={uiContext}
            lazyLoad={lazyLoad}
            listingsCount={listingsCount}
            listings={listings}
            clickType={getAlphaClickType(isAlphaMarket, true)}
        />
    );

    const renderInventoryAlphaListing = (
        <InventoryAlphaListing
            clickType={getAlphaClickType(isAlphaMarket, false)}
            enableMyWallet={enableMyWallet}
            emailOwnerProps={emailOwnerProps}
            footer={renderFooter}
            isSaved={savedListingIds.includes(({ ...listing }).id)}
            isCompared={isCompared(listing)}
            key="alphaShowcaseBody"
            lazyLoad={lazyLoad}
            listing={listing}
            listingsCount={listingsCount}
            listingCTA={listingCTA}
            onAlphaWalletClick={onAlphaWalletClick}
            onGetDirectionsClick={onGetDirectionsClick}
            onAlphaListingClick={onAlphaListingClick}
            onSaveToggle={onSaveToggle}
            onCollapsibleHeaderClick={onCollapsibleHeaderClick}
            onCompareClick={showCompareButton && onCompareClick(listing)}
            onTileClick={onTileClick}
            onPricingDetailClick={onPricingDetailClick}
            onPaymentCTAClick={onPaymentCTAClick}
            orientation={orientation}
            owner={owner}
            parentId={parentId}
            paymentsCTA={paymentsCTA}
            personalPaymentProps={personalPaymentProps}
            phoneClickHandler={phoneClickHandler}
            showAlphaPaymentRibbon={showAlphaPaymentRibbon}
            uiContext={uiContext}
            updateListingPayments={updateListingPayments}
        />
    );

    const renderAlphaShowCase = () => {
        let alpha = null;
        switch (uiContext) {
            case 'inventory':
                alpha = renderInventoryAlphaListing;
                break;
            case 'owner':
                alpha = renderDealerAlpha;
                break;
            default:
                break;
        }

        return (
            <>
                <div
                    key="alphaShowcaseSponsoredText"
                    className="margin-bottom-2 text-size-100"
                >
                    <SponsoredText
                        height={10}
                        width={52}
                    />
                </div>
                <p
                    key="alphaShowcaseTitle"
                    className="row text-size-300 text-bold padding-bottom-3 margin-vertical-0"
                >
                    <span className="col-xs-12">
                        {title}
                    </span>
                </p>

                {alpha}
            </>
        );
    };

    return (
        <div
            data-cmp="alphaShowcase"
            data-qaid="cntnr-alpha"
        >
            {uiContext ? renderAlphaShowCase() : null}
        </div>
    );

}

AlphaShowcase.propTypes = {
    /**
     * The callback function fires when owner phone is called, replacing fireTelmetricsTag
     */
    phoneClickHandler: PropTypes.func,
    /**
      * The callback function fires when text or call CTA is clicked
      */
    fireTelmetricsTag: deprecate(PropTypes.func, 'fireTelmetricsTag is deprecated in OwnerDetails. Use phoneClickHandler instead'),
    /**
     * handler for carouselSettings in carousel
     */
    carouselSettings: PropTypes.object,
    /**
     * handler for collapsible header click
     */
    onCollapsibleHeaderClick: PropTypes.func,
    /**
     * Handler for Get Directions link
     */
    onGetDirectionsClick: PropTypes.func,
    /**
     * Handler for image click in alpha listing
     */
    onAlphaListingClick: PropTypes.func,
    /**
     * Handler for inventory click in carousel
     */
    onInventoryListingClick: PropTypes.func,
    /**
     * Toggle handler for save functionality on InventoryAlphaListing
     */
    onSaveToggle: PropTypes.func,
    /**
     * Toggle handler for save functionality on InventoryAlphaListing
     */
    onCompareClick: PropTypes.func,
    /**
     * Handler for owner tile in alpha listing
     */
    onTileClick: PropTypes.func,
    /**
     * The listingCTA object has attributes passed to the anchor.
     */
    listingCTA: PropTypes.object,
    /**
     * Handler for View Owner Inventory button on Owner state or View Owner Inventory link in default state
     */
    onViewOwnerInventoryClick: PropTypes.func,
    /**
     * Handler for callback when inventory carousel settles after load or arrow click
     */
    onSettle: PropTypes.func,
    /**
     * The emailOwnerProps Object has the props to pass through to the ConnectedEmailOwner component.
     */
    emailOwnerProps: PropTypes.object,
    /**
     * The device orientation ( can be used with featureFlags to control
     * options like view dealer inventory )
     */
    orientation: PropTypes.oneOf([
        'portrait',
        'landscape',
    ]),
    /**
     * When true, the image will load when in the viewport
     */
    lazyLoad: PropTypes.bool,
    /**
     * The primary listing for InventoryAlphaListing
     */
    listing: PropTypes.object,
    /**
     * Listings to be passed to InventoryAlphaListing Carousel
     */
    listings: PropTypes.array,
    /**
     * Total count of vehicles in stock
     */
    listingsCount: PropTypes.number,
    /**
     * All saved listings in redux
     */
    savedListingIds: PropTypes.array,
    /**
     * If true, the component will render in Added mode.
     */
    isCompared: PropTypes.func,
    /**
     * The owner details object
     */
    owner: PropTypes.object,
    /**
     * Dealer click
     */
    onDealerWebsiteClick: PropTypes.func,
    /**
     * The callback function to handle pricing details link
     */
    onPricingDetailClick: PropTypes.func,
    /**
     * analytics parameter
     */
    parentId: PropTypes.string,
    /**
     * The paymentsCTA object has attributes passed to Payments.
     */
    paymentsCTA: PropTypes.object,
    /**
     * Notifies the AlphaShowcase whether to display owner or invertory Alpha listing
     */
    uiContext: PropTypes.oneOf([
        'owner',
        'inventory',
    ]),
    /**
     * The Object passed to ownerTextCTA component
     */
    ownerTxtCTA: PropTypes.object,
    /**
     * The showPaymentRibbon boolean to show/hide payment ribbon
     */
    showAlphaPaymentRibbon: PropTypes.bool,
    /**
     * Control init with the Alpha Component as visible or not
     */
    showInventory: PropTypes.bool,
    /**
     * handler for Alpha Wallet Update click
     */
    onAlphaWalletClick: PropTypes.func,
    /**
     * Object for personal payment
     */
    personalPaymentProps: PropTypes.object,
    /**
     * The callback function to handle updateListingPayments sction
     */
    updateListingPayments: PropTypes.func,
};

AlphaShowcase.displayName = 'AlphaShowcase';

export default memo(AlphaShowcase, (newProps, oldProps) => _isEqual(newProps, oldProps));
